import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useTransition,
  useSpring,
  animated,
  SpringValue,
} from '@react-spring/web';
import moment from 'moment';
import 'moment/locale/ru';

import { Task } from '../TasksNew';
import { FillableButton } from '../MedalsNew/Button';
import CompletedSignIcon from '../_shared/icons/CompletedSignIcon';
import StarsAndCircles from './StarsAndCircles';

import { StyledFC } from '../../types';
import { taskService } from '../../services';
const Badge = styled(animated.div)`
  position: relative;
  margin: -49px auto;
  width: 155px;
  height: 330px;
  border-bottom-right-radius: 28px;
  border-bottom-left-radius: 28px;
  overflow: hidden;
`;

const BadgeBackground = styled(animated.div)<{ data: Task }>`
  position: absolute;
  background-size: 100% 100%;
  background: ${({ data }) =>
    !data.completed
      ? data.exp <= 50
        ? '#8E8E96'
        : data.exp <= 200
        ? '#3935ff'
        : '#9A57F2'
      : `linear-gradient(190deg, #DFFF1D 12.71%, #90D128 74.44%)`};
  width: 100%;
  height: 330px;
`;

const XPBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: fit-content;
  color: #ebebeb;
`;

const XPNumber = styled.div`
  font-size: 40px;
  font-weight: 600;
`;

const XPText = styled.div`
  font-size: 27px;
  font-weight: 600;
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

const Text = styled.p<{ data: Task }>`
  font-size: 16.8px;
  margin: ${({ data }) =>
    !data.completed && data.progress > 0 ? `25px 0 0` : '0'};
  padding-top: 24px;
  margin-bottom: 10px;
`;

const LevelBlock = styled.div`
  height: 8px;
  position: relative;
  background-color: #d5d5d5;
  border: 0 solid black;
  border-radius: 4px;
  width: 155px;
`;

const LevelProgress = styled(animated.div)<{ data: Task }>`
  position: absolute;
  background: #90d128;
  height: 8px;
  width: ${({ data }) => (data.progress / data.total) * 100}%;
  border: 0 solid black;
  border-radius: 4px;
`;

const NameText = styled(animated.p)<{ data: Task }>`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: ${({ data }) =>
    !data.completed && data.progress > 0 ? `55px` : '80px'};
  margin-bottom: 32px;
  width: 320px;
`;

const DescriptionText = styled(animated.p)`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  width: 320px;
`;

const AchievedText = styled(animated.p)`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 320px;
`;

const AchievedDataText = styled(animated.p)`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #bdbdbd;
`;

const ButtonBlock = styled.div`
  position: absolute;
  bottom: 68px;
`;

const TaskNew: StyledFC<{ data: Task }> = ({ className, data }) => {
  const [date, setDate] = useState<string>('');
  const [show, setShow] = useState(false);
  const [badgeStyles, setBadgeStyles] =
    useState<{ backgroundSize: SpringValue<string> }>();

  const progressText = `Выполнено ${data.progress} из ${data.total}`;
  const achievedText = `Вам начислено ${data.exp} XP`;

  const animationConfig = data.completed
    ? { tension: 210, friction: 20 }
    : { tension: 120, friction: 14 };

  const transitionBadge = useTransition(show, {
    from: { y: -310 },
    enter: { y: 0 },
    leave: { x: -310 },
    delay: 1000,
    config: animationConfig,
  });

  const badgeBgStyles = useSpring({
    from: { backgroundSize: '100% 100%' },
    to: [{ backgroundSize: '100% 300%' }, { backgroundSize: '100% 100%' }],
    config: { duration: 500 },
    reset: true,
  });

  const transitionText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-150px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-150px)' },
    delay: 1000,
    config: animationConfig,
  });

  const transitionDescriptionText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-200px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-200px)' },
    delay: 1000,
    config: animationConfig,
  });

  const transitionProgress = useTransition(show, {
    from: { width: '0%' },
    enter: { width: `${(data.progress / data.total) * 100}%` },
    leave: { width: '0%' },
    delay: 1000,
  });

  const transitionAchievedText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-250px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-250px)' },
    delay: 1000,
    config: animationConfig,
  });

  const transitionAchievedDataText = useTransition(show, {
    from: { opacity: 0, transform: 'translateY(-300px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-300px)' },
    delay: 1000,
    config: animationConfig,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setBadgeStyles(badgeBgStyles);
    }, 2700);

    setShow(true);

    const dataString = moment(data.completionDate)
      .locale('ru')
      .format('LL')
      .slice(0, -3);

    setDate(`Задание выполнено ${dataString}`);

    return () => clearTimeout(timer);
  }, []);
  //@ts-ignore
  window.ym(88659232, 'reachGoal', 'expandedTask');
  useEffect(() => {
    taskService.setTaskSeen(data.playbookId);
  }, []);

  return (
    <>
      {transitionBadge(
        (styles, item) =>
          item && (
            <>
              {data.completed && <StarsAndCircles show={show} />}
              <Badge style={styles}>
                <BadgeBackground style={badgeStyles} data={data}>
                  <XPBlock>
                    {!data.completed ? (
                      <>
                        <XPNumber>{data.exp}</XPNumber>
                        <XPText>XP</XPText>
                      </>
                    ) : (
                      <CompletedSignIcon color={`#ebebeb`} size={48} />
                    )}
                  </XPBlock>
                </BadgeBackground>
              </Badge>
            </>
          )
      )}
      <ContentBlock>
        {!data.completed && data.progress > 0 && (
          <>
            <Text data={data}>{progressText}</Text>
            <LevelBlock>
              {transitionProgress(
                (styles, item) =>
                  item && <LevelProgress style={styles} data={data} />
              )}
            </LevelBlock>
          </>
        )}
        {transitionText(
          (styles, item) =>
            item && (
              <NameText data={data} style={styles}>
                {!data.completed && <>{data.descriptionToExecution}</>}
                {data.completed && <>Задание выполнено!</>}
              </NameText>
            )
        )}
        {transitionDescriptionText(
          (styles, item) =>
            item && (
              <DescriptionText style={styles}>
                {!data.completed && <>{data.motivatingPrompt}</>}
                {data.completed && (
                  <>
                    <div>Вы выполнили задание:</div>
                    <div>{data.descriptionToExecution}</div>
                  </>
                )}
              </DescriptionText>
            )
        )}

        {data.completed && (
          <>
            {transitionAchievedText(
              (styles, item) =>
                item && (
                  <AchievedText style={styles}>{achievedText}</AchievedText>
                )
            )}
            {transitionAchievedDataText(
              (styles, item) =>
                item && (
                  <AchievedDataText style={styles}>{date}</AchievedDataText>
                )
            )}
          </>
        )}

        {!data.completed && (
          <ButtonBlock>
            <a href={data.linkToTask} target='_blank' rel='noreferrer'>
              <FillableButton padding='12px 16px'>
                {data.buttonText}
              </FillableButton>
            </a>
          </ButtonBlock>
        )}
      </ContentBlock>
    </>
  );
};

export default TaskNew;
