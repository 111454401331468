import { API } from '../_api/axios';
import { Medal } from '../components/MedalsNew/Grid';

export const medalsService = {
  getMedalsInfo: () => API.get('medals/info'),
  setMedalSeen: (playbookId: number) =>
    API.post(`achievement-open-desc/${playbookId}`),
  getAllMedals: (pageSize: number, category: string) =>
    API.get<Medal[]>(`/medals?filter=${category}&page=1&page_size=${pageSize}`),
};
