import React, { useEffect, useState } from 'react';
import { StyledFC } from '../../../types';
import styled from 'styled-components';

import RoundedBox from '../../_shared/RoundedBox';
import Divider from '../../_shared/Divider';
import AcceptIcon from '../../_shared/icons/AcceptIcon';

import {ranksServices} from "../../../services";

const _RankPage: StyledFC<any> = (props,{ className }) => {
  const [ranksList, setRanksList] = useState<
    {
      id: number;
      medalSrc: string;
      name: string;
      description: string;
      earned: boolean;
        descriptionReceived:string;

    }[]
  >([]);

  const changeRank = (r:any) => {
    if (ranksList.length) {
     ranksServices.setRank(r.id).then(() => {
props.setProfileRank(r);

          //@ts-ignore
          window.ym(88659232, 'userParams', {
            UserID: 12345,
          });
          //@ts-ignore
          window.ym(88659232, 'reachGoal', 'changeTitle');
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
      ranksServices.openRanks();
    ranksServices.getRanks()
      .then(({ data }) => {
        setRanksList(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className={[props.className, 'header-wrapper'].join(' ')}>
      <div className={'optionsList'}>
        <RoundedBox padding={'6px 15px 15px 15px'}>
          {ranksList.filter(r=>r.earned===true).map((r, i) => (
            <React.Fragment key={r.id}>
                <div>
              <div className={'menu-item'} onClick={() => changeRank(r)}>

                      <div className='img-wrapper'>
                          <img
                              alt='icon'
                              src={r.medalSrc}
                              height={52}
                              width={52}
                          />
                      </div>


                <div>
                  <h6
                    className={`chosen-rank`}
                  >
                    {r.name}
                  </h6>
                  <label>{r.descriptionReceived}</label>
                </div>
                  {r.id === props.profileData.rankId ? (
                      <div className={'chosen-flag'}>
                          <AcceptIcon size={20} color={'black'} />
                      </div>
                  ) : (
                      <></>
                  )}
              </div>
              {i + 1 < ranksList.length ? <Divider /> : <></>}
                </div>
            </React.Fragment>
          ))}
            {ranksList.filter(r=>r.earned===false).map((r, i) => (
                <React.Fragment key={r.id}>
                    <div>
                        <div className={'menu-item'} >

                                <div className='img-blocked'>
                                    <img
                                        alt='icon'
                                        src={r.medalSrc}
                                        height={52}
                                        width={52}
                                    />
                                </div>


                            <div>
                                <h6
                                    className={`chosen-rank`}
                                >
                                    {r.name}
                                </h6>
                                <label>{r.description}</label>
                            </div>
                            {r.id === props.profileData.rankId ? (
                                <div className={'chosen-flag'}>
                                    <AcceptIcon size={20} color={'black'} />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        {i + 1 < ranksList.length ? <Divider /> : <></>}
                    </div>
                </React.Fragment>
            ))}
        </RoundedBox>
      </div>
    </div>
  );
};

const RankPage = styled(_RankPage)`
  .header-wrapper {
    @media screen and (min-width: 481px) {
      margin-top: 3px;
    }
  }
  
  .header {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: black;
  }
  .optionsList {
    width: 420px;
 margin-top: 20px;
  }
  .menu-item {
    position: relative;
    cursor: pointer;
    width: 350px;
    padding: 24px 16px 24px 0;
    display: flex;
    flex-direction: row;
    align-items: center;


    .img-wrapper {
      margin-right: 10px;
      display: grid;
      width: 52px;
      height: 52px;
    }
    .img-blocked {
      filter: grayscale(100%);
      margin-right: 10px;
      display: grid;
      width: 52px;
      height: 52px;
    }

    .chosen-flag {
      position: absolute;
      left: 360px;

    }

    .locked {
      color: #868686 !important;
    }

    .chosen-rank {
      color: black;
    }

    & > div {
      & > h6 {
        cursor: pointer;

        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 110%;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.02em;
        color: #ebebeb;
        margin: 0;
      }

      & > label {
        cursor: pointer;

        font-weight: 400;
        font-size: 14px;
        line-height: 110%;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.02em;
        color: #868686;
        margin-top: 8px;
      }
    }
  }
`;

export default RankPage;
